import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreManager } from './local-store-manager.service';
import { Device } from '../models/device.model';

@Injectable()
export class DeviceService {
  private devicesUrl: string = 'api/devices';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager) { }

  getdevices(dev:number): Observable<Device[]> {
    let sitename = this.localStorage.getDataObject("sitename");
    let endpointUrl = `${this.devicesUrl}/${sitename}:${dev}`;
    return this.http.get<Device[]>(endpointUrl);
  }

}

