import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import { ProjectModule } from './project/project.module';
import { PersonsModule } from './persons/persons.module';
import { AdminModule } from './administrator/admin.module';
import { SiteService } from './services/site.service';
import { JwtInterceptor } from './providers/jwt.interceptor';
import { ErrorInterceptor } from './providers/error.interceptor';
import { LocalStoreManager } from './services/local-store-manager.service';
import { DataService } from './services/data.service';
import { DeviceService } from './services/device.service';
import { AlertService } from './services/alert.service';
import { DialogService } from './services/dialog.service';
import { AppTranslationService, TranslateLanguageLoader  } from './services/app-translation.service';
import { AppComponent } from "./components/app.component";
import { HomeComponent } from "./components/home/home.component";
import { AlertComponent } from './components/alert/alert.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
/*import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';*/
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
    PersonsModule,
    AdminModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    TimepickerModule.forRoot(),
/*    NgxMaterialTimepickerModule,*/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    HttpClientModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AlertComponent,
    ConfirmComponent
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    DataService,
    SiteService,
    AlertService,
    DialogService,
    DeviceService,
    AppTranslationService,
    LocalStoreManager
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
