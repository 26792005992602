import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorage: LocalStoreManager) {
  }
  rights: number;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {

    const url = `/${route.path}`;
    return this.checkLogin(url);
  }


  checkLogin(url: string): boolean {

    if (this.authService.isLoggedIn) {
      //this.router.navigate(['url']);
      return true;
    }
      
    //  this.authService.loginRedirectUrl = url;
    //  if (url == "/about") {
    //    this.router.navigate(['/about']);
    //  }
    //  else if (url == "/substitute") {
    //    this.router.navigate(['/substitute']);
    //  }
    //  else if (url == "/projects") {
    //    this.router.navigate(['/projects']);
    //  }
    //  else {
    //    this.rights = this.localStorage.getDataObject("rights");
    //    if ((this.rights & 1) == 1)
    //      this.router.navigate(['/stampreport']);
    //    else if ((this.rights & 2) == 2)
    //        this.router.navigate(['/stampcorrection']);
    //    else if ((this.rights & 4) == 4)
    //        this.router.navigate(['/saldocorrection']);
    //    else if ((this.rights & 8) == 8)
    //      this.router.navigate(['/unaccepteddays']);
    //    else if ((this.rights & 16) == 16)
    //      this.router.navigate(['/infostate']);
    //    else if ((this.rights & 32) == 32)
    //      this.router.navigate(['/clockingreport']);
    //    else if ((this.rights & 64) == 64)
    //      this.router.navigate(['/overtimereport']);
    //    else if ((this.rights & 128) == 128)
    //      this.router.navigate(['/statereport']);
    //    else if ((this.rights & 256) == 256)
    //      this.router.navigate(['/vacationreport']);
    //    else if ((this.rights & 512) == 512)
    //      this.router.navigate(['/absencecodereport']);
    //    else if ((this.rights & 1024) == 1024)
    //      this.router.navigate(['/stampproposal']);
    //    else if ((this.rights & 4096) == 4096)
    //      this.router.navigate(['/absencecodesumreport']);
    //    else if ((this.rights & 8192) == 8192)
    //      this.router.navigate(['/maxworkinghours']);
    //    else if ((this.rights & 16384) == 16384)
    //      this.router.navigate(['/saldoreport']);
    //    else if ((this.rights & 32768) == 32768)
    //      this.router.navigate(['/worknumberreport']);
    //    else {
    //      this.router.navigate(['/login']);
    //      return false;
    //    }
    //  }
    //  return true;
    //}
    this.authService.redirectLogoutUser();
    return false;
  }

}
