import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { SharedMaterialModule } from '../shared-mat.module';
import { PersonsRoutingModule } from './persons-routing.module';
import { PersonsComponent } from './persons.component';
import { PersonsListComponent } from './persons-list/persons-list.component';
import { PersonsDialogComponent } from './persons-list/persons-dialog.component';
import { PersonsEditorComponent } from './persons-list/persons-editor.component';
import { PersonsTaEditorComponent } from './persons-list/persons-taeditor.component';
import { PersonsCalendarListComponent } from './persons-list/persons-calendarlist.component';

import { OrganizationHierarchyComponent } from './organizationhierarchy/organizationhierarchy.component';
import { OrganizationTreeComponent } from './organizationtree/organizationtree.component';
import { PersonAuxDataComponent } from './personauxdata/personauxdata.component';
import { PersonAuxDataEditorComponent } from './personauxdata/personauxdata-editor.component';
import { PersonAuxDataDialogComponent } from './personauxdata/personauxdata-dialog.component';
import { ChangePassword } from './persons-list/setpassword.component';

import { PersonNameService } from '../services/personname.service';
import { EmployeeGroupService } from '../services/employeegroup.service';
import { ProfessionService } from '../services/profession.service';
import { CostPlaceService } from '../services/costplace.service';
import { PersonsToolTipDialog } from './persons-tooltip/persons-tooltip.component';
import { UserTypeService } from '../services/usertype.service';
import { AcceptLevelService } from '../services/acceptlevel.service';
import { SupplierGroupService } from '../services/suppliergroup.service';
import { WorkingPosService } from '../services/workingpos.service';
import { UserLanguageService } from '../services/userlanguage.service';
import { PersonCalendarService } from '../services/personcalendar.service';
import { BaseCalendarService } from '../services/basecalendar.service';
import { OrganizationService } from '../services/organization.service';



@NgModule({
  imports: [
    SharedModule,
    SharedMaterialModule,
    PersonsRoutingModule
  ],
  declarations: [
    PersonsComponent,
    PersonsListComponent,
    PersonsDialogComponent,
    PersonsEditorComponent,
    PersonsTaEditorComponent,
    PersonsCalendarListComponent,
    OrganizationTreeComponent,
    PersonAuxDataComponent,
    PersonAuxDataEditorComponent,
    PersonAuxDataDialogComponent,
    OrganizationHierarchyComponent,
    PersonAuxDataComponent,
    ChangePassword,
    PersonsToolTipDialog
  ],
  providers: [
    PersonNameService,
    EmployeeGroupService,
    UserTypeService,
    ProfessionService,
    AcceptLevelService,
    SupplierGroupService,
    WorkingPosService,
    UserLanguageService,
    PersonCalendarService,
    BaseCalendarService,
    OrganizationService,
    CostPlaceService
  ]
})
export class PersonsModule {
}
