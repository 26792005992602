import { Component, OnInit } from '@angular/core';
import { Device } from '../../models/device.model';

@Component({
  selector: 'device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

 
}
