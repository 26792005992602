import { Component, ChangeDetectorRef, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from "@angular/core";
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AlertService, AlertDialog, DialogType } from '../services/alert.service';
import { AppTranslationService } from "../services/app-translation.service";
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AuthService } from '../services/auth.service';
import { LoginDialogComponent } from '../components/login/login-dialog.component';
import { SharedMaterialModule } from '../shared-mat.module';
import { MatSidenav } from '@angular/material/sidenav';
import { filter } from 'rxjs/operators';

var alertify: any = require('../assets/scripts/alertify.js');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
/*  encapsulation: ViewEncapsulation.None*/
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('project') projectExpander: MatExpansionPanel;
  @ViewChild('mainNav', { static: true }) mainNav: MatSidenav;

  private _mobileQueryListener: () => void;
  private isFirstRouteChange = true;
  hasSubstitutes: boolean;
  isUserLoggedIn: boolean;
  isProjectExpanded = false;
  shouldShowLoginModal: boolean;
  appTitle = "PremiTime Plus";
  siteTitle = "DEMOX";
  mobileQuery: MediaQueryList;
  panelOpenState: boolean = false;
  reportRights: number;
  userRights: number;
  readRights
  projs: number;
  personRight: boolean = false;
  personCount: number = 0;
  private _subscription;
  private _subscription1;

  private handleData(data: string) {
    this.translationService.setDefaultLanguage(data);
  }

  constructor(
    private localStorage: LocalStoreManager,
    private alertService: AlertService,
    public authService: AuthService,
    private translationService: AppTranslationService,
    public dialog: MatDialog,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher)
  {
    localStorage.initialiseStorageSyncListener();
    this.shouldShowLoginModal = false;
    translationService.addLanguages(["en", "fi", "sv"]);
    this.translationService.setDefaultLanguage("fi");
    this._subscription1 = authService.userLogInChange.subscribe((value) => { this.isUserLoggedIn = value; });
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
    };
  }

  about() {
    if (this.isUserLoggedIn)
      this.router.navigate(['/about']);
  }

  logout() {
    console.log("Logging out");
    this.authService.logout()
      .subscribe(results => {
        this.authService.redirectLogoutUser();
      },
        error => {
           console.log(error);
        });

  }

  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;
    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.authService.reLoginDelegate = () => this.showLoginDialog();
    this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;
    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;
      this.personCount = this.localStorage.getDataObject("personcount");
      this.personRight = this.personCount > 0;
    });

    // Listen for the NavigationEnd event
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (this.isFirstRouteChange && event.url == "/") {
          // Toggle the sidenav when a route changes
          this.mainNav.toggle();
          setTimeout(() => {
            this.mainNav.toggle();
          }, 100); // delay in milliseconds
          //}
          this.isFirstRouteChange = false;
        }
      });
  }

  showLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, { minWidth: 600 });
  }

  ngOnDestroy() {
    //prevent memory leak when component destroyed
    this._subscription.unsubscribe();
    this._subscription1.unsubscribe();
  }

  onLoginModalHidden() {
    this.shouldShowLoginModal = false;
  }



  get canViewReports() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights&32767) > 0);
  }
  get canViewAcceptMenu() {
    return (this.canViewStampCorrection || this.canViewSaldoCorrection || this.canViewUnAcceptedDays);
  }
  get canViewStampCorrection() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 8) == 8 && this.personRight);
  }

  get canViewSaldoCorrection() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 16) == 16 && this.personRight);
  }

  get canViewUnAcceptedDays() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 32) == 32 && this.personRight);
  }

  get canViewStampProposal() {
    this.userRights = this.localStorage.getDataObject("userrights");
    return ((this.userRights & 1) == 1 && this.personRight);
  }

  get canViewAbsenceCodes() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 64) == 64 && this.personRight);
  }
    get canViewPersonsMenu() {
    return (this.canViewPersons || this.canViewOrganizations || this.canViewPersonAuxData);
  }
  get canViewPersons() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 128) == 128 && this.personRight);
  }
  get canViewOrganizations() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 256) == 256);
  }
  get canViewPersonAuxData() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 512) == 512);
  }
  get canViewAdministrator() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 16384) == 16384);
  }

  // Reports using reportrights
  get canViewStampReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 1) == 1 && this.personRight);
  }
  get canViewClockingReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 32) == 32 && this.personRight);
  }
  get canViewOverTimeReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 64) == 64 && this.personRight);
  }

  get canViewStateReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 128) == 128 && this.personRight);
  }
  get canViewVacationReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 256) == 256 && this.personRight);
  }
  get canViewSumReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 2048) == 2048 && this.personRight);
  }
  get canViewAbsenceCodeReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 512) == 512 && this.personRight);
  }


  get canViewAbsenceCodeSumReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 1024) == 1024 && this.personRight);
  }
  get canViewMaxWorkingHours() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 2) == 2 && this.personRight);
  }
  get canViewSaldoReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 4) == 4 && this.personRight);
  }
  get canViewWorkNumberReport() {
    this.reportRights = this.localStorage.getDataObject("reportrights");
    return ((this.reportRights & 8) == 8 && this.personRight);
  }

  get canViewProjectMenu() {
    return (this.canViewProjects && (this.canViewProjectAdmin || this.canViewProjectUser));
  }
  get canViewProjects() {
    this.projs = this.localStorage.getDataObject("projects");
    return (this.projs > 0);
  }
  get canViewProjectAdmin() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 4096) == 4096 && this.personRight);
  }
  get canViewProjectUser() {
    this.userRights = this.localStorage.getDataObject("readrights");
    return ((this.userRights & 8192) == 8192 && this.personRight);
  }

  get canViewSubstitute() {
    this.hasSubstitutes = this.localStorage.getDataObject("hassubstitutes");
    if (this.hasSubstitutes)
      return this.hasSubstitutes;
  }

  showDialog(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || "OK",
        cancel: dialog.cancelLabel || this.translationService.getTranslation('common.Cancel')
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          }, dialog.defaultValue);

        break;
    }
  }



}

